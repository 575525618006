import { styled } from '@linaria/react';
import { Swiper } from 'swiper/react';

import { LottieAnimation } from '@/components/LottieAnimation';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';
import { RMWordmark } from '@/components/RMWordmark/RMWordmark';

export const PageRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
`;

export const ConfettiAnimation = styled(LottieAnimation)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100dvw;
  z-index: 10;
  pointer-events: none;
`;

export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 5rem;
  width: 100%;
  padding: 0 var(--spacing-3xl);

  body[data-mobile='true'] & {
    justify-content: center;
    min-height: 3rem;
    border-bottom: 1px solid var(--border-hairline);
  }
`;

export const PageHeaderWordmark = styled(RMWordmark)`
  body[data-mobile='true'] & {
    height: 1rem;
  }
`;

export const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);
  max-width: 1168px;
  width: 100%;
  padding: var(--spacing-3xl);
  padding-bottom: var(--spacing-6xl);
  margin: 0 auto;

  body[data-mobile='true'] & {
    padding: var(--spacing-lg);
    padding-bottom: var(--spacing-4xl);
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--border-hairline);
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
`;

export const OfferWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  body[data-mobile='true'] & {
    flex-direction: column-reverse;
    gap: var(--spacing-2xl);
  }
`;

export const OfferTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  max-width: 556px;
  margin-right: var(--spacing-xl);
`;

export const OfferCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-2xl);
  max-width: 380px;
  width: 100%;

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    align-self: center;
  }
`;

export const OfferCard = styled.div`
  position: relative;
  background-color: var(--surface-dim);
  border: 1px solid var(--border-hairline);
  border-radius: var(--radius-round);
  max-height: 343px;
  width: 100%;
  height: 100%;
`;

export const OfferCardBanner = styled.div`
  display: flex;
  justify-content: center;
  background-color: var(--color-aqua-90);
  padding: var(--spacing-md);
  border-top-left-radius: var(--radius-round);
  border-top-right-radius: var(--radius-round);
`;

export const OfferCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding: var(--spacing-2xl);
`;

export const OfferCardPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xl);
`;

export const OfferCardButton = styled(RMButton)`
  margin-top: var(--spacing-md);
`;

export const OfferCardBubble = styled.div`
  position: absolute;
  top: -32px;
  right: -36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 86px;
  border-radius: 100%;
  background: linear-gradient(180deg, hsla(64, 79%, 79%, 1) 0%, hsla(64, 81%, 61%, 1) 100%);
  border: 1px solid hsla(64, 79%, 70%, 1);
  box-shadow: 0px 4px 4px 0px hsla(0, 0%, 0%, 0.25);

  body[data-mobile='true'] & {
    display: none;
  }
`;

export const ClickableText = styled(RMText)`
  cursor: pointer;
  text-decoration: underline;
`;

export const Bullets = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
`;

export const BulletItem = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
`;

export const MomentsWrapper = styled.div`
  display: flex;
  gap: var(--spacing-2xl);

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    flex-direction: column;
  }
`;

export const MomentsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);

  &[data-center='true'] {
    justify-content: center;
  }
`;

export const MomentCards = styled.div`
  display: flex;
  gap: var(--spacing-lg);

  body[data-tablet='true'] & {
    justify-content: center;
  }
  body[data-mobile='true'] & {
    flex-direction: column;
  }
`;

export const MomentCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-sm);
  width: 233px;
  height: 212px;
  border: 1px solid var(--border-hairline);
  background-color: var(--surface-bright);
  box-shadow: var(--elevation-bottom-1);
  border-radius: 24px;

  body[data-mobile='true'] & {
    flex-direction: row;
    width: 100%;
    height: 101px;
  }
`;

export const StoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const StyledSwiper = styled(Swiper)`
  --swiper-pagination-color: black;
  width: 100%;
  padding-bottom: var(--spacing-2xl);
`;

const gridColumn = 'calc(33.33% - var(--spacing-lg) * 2 / 3)';
export const StoriesGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${gridColumn} ${gridColumn} ${gridColumn};
  grid-gap: var(--spacing-lg);
`;

export const StoryTextCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-xs);
  width: 100%;
  height: 17.5rem;

  body[data-mobile='true'] & {
    height: 15rem;
  }
`;

export const EmptyStoriesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-4xl);

  body[data-mobile='true'] & {
    flex-direction: column-reverse;
    gap: var(--spacing-xl);
  }
`;

export const EmptyStoryText = styled(RMText)`
  max-width: 360px;
`;

export const EmptyStoryVideo = styled.video`
  perspective: 690 / 389;
`;

export const PromptsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const PromptsGrid = styled.div`
  display: flex;
  gap: var(--spacing-md);

  body[data-mobile='true'] & {
    flex-direction: column;
  }
`;

export const PromptCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  max-width: 266px;
  width: 100%;
  height: 180px;
  padding: var(--spacing-md);
  border: 1px solid var(--border-hairline);
  background: var(--surface-bright);
  box-shadow: var(--elevation-bottom-1);
  border-radius: 1.5rem;

  body[data-mobile='true'] & {
    height: 139px;
    max-width: 100%;
  }
`;

export const Blurred = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  filter: blur(8px);
`;

export const RemainingPromptsCount = styled(RMText)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid var(--primary-decorative);
  border-radius: var(--radius-full);
`;

export const ClaimOfferCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-2xl);
  padding: var(--spacing-2xl);
  background-color: var(--surface-dim);
  border-radius: var(--radius-round);

  body[data-mobile='true'] & {
    flex-direction: column;
    padding: var(--spacing-xl) var(--spacing-md) var(--spacing-md);
  }
`;

export const ClaimOfferCardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 569px;

  body[data-mobile='true'] & {
    gap: var(--spacing-md);
  }
`;

export const ClaimOfferCardButton = styled(RMButton)`
  height: 60px;
  max-width: 332px;
  width: 100%;
`;
