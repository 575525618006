import { useCallback, useMemo } from 'react';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { AclGroupRole } from '@remento/types/acl';
import { SubscriptionType } from '@remento/types/user';

import SubscriptionReactivateImageSrc from '@/assets/subscription-reactivate.webp';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { useIsMobileOrTabletViewport, useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { SubscriptionFaqList } from '@/modules/account-subscription/components/SubscriptionFaqList/SubscriptionFaqList';
import { RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePeople } from '@/services/api/person';
import { useProjectsItems } from '@/services/api/project';
import { captureException } from '@/utils/captureException';

import {
  Divider,
  HeaderTextWrapper,
  PageBody,
  PageHeader,
  PageHeaderWordmark,
  PageRoot,
} from '../AccountRenewPromoPage/AccountRenewPromoPage.styles';

import {
  FeaturesImage,
  FeaturesList,
  FeaturesListItem,
  FeaturesListItemIcon,
  FeaturesListWrapper,
  FeaturesWrapper,
  PlanCard,
  PlanCardBanner,
  PlanCardContent,
  PlanCardPriceText,
  PlanCardPriceWrapper,
  PlanCardTitle,
  PlanCardWrapper,
  PlansWrapper,
} from './AccountReactivatePage.styles';

const FEATURES = [
  'Set up more prompts for your Storytellers.',
  'Start recording new stories or re-record previous stories.',
  'Edit your written stories and use our AI Writing Assistant to help write new versions of your stories.',
  'Add or edit photos.',
  'Start unlimited new projects (that will someday be their own books!).',
  'Order books anytime.',
];

function InternalAccountReactivatePage() {
  const { checkoutService } = useServices();
  const isMobile = useIsMobileViewport();
  const isMobileOrTablet = useIsMobileOrTabletViewport();

  // Queries
  const user = useUser();
  const projects = useProjectsItems(AclGroupRole.OWNER);

  // Get storyteller names
  const storytellerPersonIds = useMemo(
    () => Array.from(new Set(projects.map((p) => p.notifications.recipientPersonIds[0]))),
    [projects],
  );
  const storytellerPeople = usePeople(storytellerPersonIds);
  const storytellersNames = useMemo(() => {
    const names = storytellerPeople
      .sort((a, b) => {
        if (a.refIds.includes(user?.personId ?? '')) {
          return -1;
        }
        if (b.refIds.includes(user?.personId ?? '')) {
          return 1;
        }
        return (a.name?.first ?? 'Someone').localeCompare(b.name?.first ?? 'Someone');
      })
      .map((p) => {
        if (p.refIds.includes(user?.personId ?? '')) {
          return 'you';
        }
        return p.name?.first ?? 'Someone';
      });
    return new Intl.ListFormat('en-US', { style: 'long' }).format(names);
  }, [storytellerPeople, user?.personId]);

  // Callbacks
  const handleReactivate = useCallback(
    async (type: SubscriptionType) => {
      try {
        const checkout = await checkoutService.reactivate(type);
        window.open(checkout.paymentUrl, '_self');
      } catch (error) {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    },
    [checkoutService],
  );

  return (
    <PageRoot>
      <PageHeader>
        <PageHeaderWordmark />
      </PageHeader>
      <PageBody>
        <HeaderTextWrapper>
          <RMText type="serif" size={isMobile ? 'xl' : 'xxl'} color="black" align="center">
            Reactivate your Remento subscription
          </RMText>
          <RMText type="sans" size={isMobile ? 's' : 'm'} color="on-surface-primary" align="center">
            Ensure {storytellersNames} can continue telling stories for another year.
          </RMText>
        </HeaderTextWrapper>

        <PlansWrapper>
          {/* Monthly */}
          <PlanCardWrapper>
            <PlanCard>
              <PlanCardContent>
                <PlanCardTitle type="sans" size="l" color="on-surface-primary" bold>
                  Monthly
                </PlanCardTitle>
                <PlanCardPriceWrapper>
                  <PlanCardPriceText type="serif" size="xxxl" color="black">
                    $12
                  </PlanCardPriceText>
                  <RMText type="serif" size="s" color="on-surface-tertiary" italic>
                    / mo
                  </RMText>
                </PlanCardPriceWrapper>
                {isMobileOrTablet == false && (
                  <RMText type="serif" size="s" color="on-surface-tertiary" italic>
                    &nbsp;
                  </RMText>
                )}
              </PlanCardContent>
              <RMButton
                background="primary"
                fullWidth
                autoLoading
                onClick={() => handleReactivate(SubscriptionType.MONTHLY)}
              >
                Select
              </RMButton>
            </PlanCard>
          </PlanCardWrapper>
          {/* Annual */}
          <PlanCardWrapper>
            <PlanCardBanner>
              <RMText type="sans" size="s" color="on-surface-primary" bold>
                MOST POPULAR
              </RMText>
            </PlanCardBanner>
            <PlanCard data-banner>
              <PlanCardContent>
                <PlanCardTitle type="sans" size="l" color="on-surface-primary" bold>
                  Annual
                </PlanCardTitle>
                <PlanCardPriceWrapper>
                  <PlanCardPriceText type="serif" size="xxxl" color="black">
                    $8.25
                  </PlanCardPriceText>
                  <RMText type="serif" size="s" color="on-surface-tertiary" italic>
                    / mo
                  </RMText>
                </PlanCardPriceWrapper>
                <RMText type="serif" size="s" color="on-surface-tertiary" italic>
                  $99 billed annually
                </RMText>
              </PlanCardContent>
              <RMButton
                background="primary"
                fullWidth
                autoLoading
                onClick={() => handleReactivate(SubscriptionType.YEARLY)}
              >
                Select
              </RMButton>
            </PlanCard>
          </PlanCardWrapper>
        </PlansWrapper>

        <RMText type="sans" size="s" color="on-surface-primary" align="center">
          Know someone else who wants to pay for this storyteller’s account?
          <br />
          Send us a note at{' '}
          <a href="mailto:support@remento.co">
            <RMText type="sans" size="s" color="on-surface-primary" underline>
              support@remento.co
            </RMText>
          </a>
          .
        </RMText>

        <Divider />

        <FeaturesWrapper>
          <FeaturesImage src={SubscriptionReactivateImageSrc} alt="image" />

          <FeaturesListWrapper>
            <RMText type="sans" size="m" color="on-surface-primary" bold>
              When you renew with Remento, you can:
            </RMText>

            <FeaturesList>
              {FEATURES.map((feature) => (
                <FeaturesListItem key={feature}>
                  <FeaturesListItemIcon icon={faCheck} size="sm" />
                  <RMText type="sans" size={isMobile ? 'xs' : 'm'} color="on-surface-primary">
                    {feature}
                  </RMText>
                </FeaturesListItem>
              ))}
            </FeaturesList>
          </FeaturesListWrapper>
        </FeaturesWrapper>

        <Divider />

        <SubscriptionFaqList />
      </PageBody>
    </PageRoot>
  );
}

export function AccountReactivatePage() {
  return (
    <RementoPage type="default">
      <InternalAccountReactivatePage />
    </RementoPage>
  );
}
