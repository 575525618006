import 'swiper/css';
import 'swiper/css/pagination';

import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AclGroupRole } from '@remento/types/acl';
import { UnknownError } from '@remento/types/error';
import { PromptStatus } from '@remento/types/project';
import { notNull } from '@remento/utils/array/notNull';
import dayjs from 'dayjs';
import { Pagination } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';

import ConfettiAnimationData from '@/assets/confetti.lottie.json';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { useAsyncEffect } from '@/hooks';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { SubscriptionFaqList } from '@/modules/account-subscription/components/SubscriptionFaqList/SubscriptionFaqList';
import { getAccountSettingsPath, RementoPage } from '@/modules/routing';
import { StoriesItemContainer } from '@/modules/stories/containers/Stories.container';
import { StoryDialogContainer } from '@/modules/stories/containers/StoryDialog.container';
import { createStoriesManager, setActiveStoryId, setStoriesIds } from '@/modules/stories/states/stories.manager';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePeople } from '@/services/api/person';
import { useProjectsItems } from '@/services/api/project';
import { usePurchaserOnboardingPromptTemplatesQuery } from '@/services/cms/prompt-template/prompt-template.service.hook';
import { captureException } from '@/utils/captureException';

import {
  Blurred,
  BulletItem,
  Bullets,
  ClaimOfferCard,
  ClaimOfferCardButton,
  ClaimOfferCardTextWrapper,
  ClickableText,
  ConfettiAnimation,
  Divider,
  EmptyStoriesWrapper,
  EmptyStoryText,
  EmptyStoryVideo,
  HeaderTextWrapper,
  MomentCard,
  MomentCards,
  MomentsTextWrapper,
  MomentsWrapper,
  OfferCard,
  OfferCardBanner,
  OfferCardBubble,
  OfferCardButton,
  OfferCardContent,
  OfferCardPriceWrapper,
  OfferCardWrapper,
  OfferTextWrapper,
  OfferWrapper,
  PageBody,
  PageHeader,
  PageHeaderWordmark,
  PageRoot,
  PromptCard,
  PromptsGrid,
  PromptsWrapper,
  RemainingPromptsCount,
  StoriesGrid,
  StoriesWrapper,
  StoryTextCard,
  StyledSwiper,
} from './AccountRenewPromoPage.styles';

function SwipableStoriesGrid({ children }: PropsWithChildren<unknown>) {
  const isMobile = useIsMobileViewport();

  if (isMobile) {
    return (
      <StyledSwiper pagination={true} modules={[Pagination]}>
        {children}
      </StyledSwiper>
    );
  }

  return <StoriesGrid>{children}</StoriesGrid>;
}

function InternalAccountRenewPromoPage() {
  // Services
  const { projectCacheService, storyCacheService, checkoutService } = useServices();
  const navigate = useNavigate();
  const isMobile = useIsMobileViewport();

  // Queries
  const user = useUser();
  const projects = useProjectsItems(AclGroupRole.OWNER);

  // Get storyteller names
  const storytellerPersonIds = useMemo(
    () => Array.from(new Set(projects.map((p) => p.notifications.recipientPersonIds[0]))),
    [projects],
  );
  const storytellerPeople = usePeople(storytellerPersonIds);
  const storytellersNames = useMemo(() => {
    const names = storytellerPeople
      .sort((a, b) => {
        if (a.refIds.includes(user?.personId ?? '')) {
          return -1;
        }
        if (b.refIds.includes(user?.personId ?? '')) {
          return 1;
        }
        return (a.name?.first ?? 'Someone').localeCompare(b.name?.first ?? 'Someone');
      })
      .map((p) => {
        if (p.refIds.includes(user?.personId ?? '')) {
          return 'you';
        }
        return p.name?.first ?? 'Someone';
      });
    return new Intl.ListFormat('en-US', { style: 'long' }).format(names);
  }, [storytellerPeople, user?.personId]);

  // Compute statistics
  const statistics = useMemo(
    () =>
      projects.reduce(
        (s, p) => ({
          totalPromptsSent: s.totalPromptsSent + p.statistics.promptsSent,
          totalStoriesRecorded: s.totalStoriesRecorded + p.statistics.storiesRecorded,
          totalRecordedTime: s.totalRecordedTime + p.statistics.storiesRecordedTime,
        }),
        {
          totalPromptsSent: 0,
          totalStoriesRecorded: 0,
          totalRecordedTime: 0,
        },
      ),
    [projects],
  );
  const showUserStatistics = statistics.totalStoriesRecorded > 0;

  const offerExpireOn = useMemo(() => {
    if (user?.subscription == null) {
      return 'EXPIRES TODAY';
    }
    const expireOn = dayjs(user.subscription.endsOn);
    const daysUntilExpire = expireOn.diff(Date.now(), 'day');
    return `EXPIRES IN ${daysUntilExpire} DAY${daysUntilExpire > 1 ? 'S' : ''}`;
  }, [user?.subscription]);

  // Load up to 3 stories
  const [userStoriesIds, setUserStoriesIds] = useState<Array<string>>([]);
  useAsyncEffect(
    async (checkpoint) => {
      if (projects.length == 0) {
        return;
      }

      // Load the promises as soon as possible
      const newStories: Array<string> = [];
      await Promise.all(
        projects
          .filter((p) => p.statistics.storiesRecorded > 0)
          .map((p) =>
            storyCacheService.getProjectStories(p.id).then(async (storiesIds) => {
              checkpoint();

              for (const storyId of storiesIds) {
                if (newStories.length >= 3) {
                  // We don´t need more stories
                  return;
                }

                const story = await storyCacheService.getStory(storyId);
                checkpoint();

                if (story != null && story.recordingsIds.length > 0) {
                  if (newStories.length < 3) {
                    newStories.push(story.id);
                  }
                }
              }
            }),
          ),
      );
      checkpoint();
      setUserStoriesIds(newStories);
    },
    [projects],
  );

  // Load the remaining prompts
  const [prompts, setPrompts] = useState<Array<{ id: string; question: string }>>([]);
  useAsyncEffect(
    async (checkpoint) => {
      if (projects.length == 0) {
        return;
      }

      const allIds = await Promise.all(projects.map((p) => projectCacheService.getProjectPrompts(p.id)));
      checkpoint();
      const allPrompts = await Promise.all(allIds.flat().map((id) => projectCacheService.getPrompt(id)));
      checkpoint();

      const pendingPrompts = allPrompts.filter(notNull).filter((p) => p.status === PromptStatus.SENT);
      const questionsIds = await Promise.all(pendingPrompts.map((p) => projectCacheService.getPromptQuestions(p.id)));
      checkpoint();

      const questions = await Promise.all(questionsIds.flat().map((id) => projectCacheService.getQuestion(id)));
      checkpoint();

      const newPrompts: Array<{ id: string; question: string }> = [];
      for (const pendingPrompt of pendingPrompts) {
        const question = questions.find((q) => q != null && pendingPrompt.refIds.includes(q.promptId));
        if (question == null) {
          continue;
        }
        newPrompts.push({
          id: pendingPrompt.id,
          question: question.text ?? '',
        });
      }
      setPrompts(newPrompts);
    },
    [projects],
  );

  // Load questions from the bank in case the user have no prompt in the bank
  const promptTemplatesQuery = usePurchaserOnboardingPromptTemplatesQuery();

  // Create a story manager to show the full video
  const storiesManager = useMemo(() => createStoriesManager([], '', null, null, 'story-standalone'), []);

  // Callbacks
  const handleRenew = useCallback(async () => {
    try {
      const checkout = await checkoutService.earlyRenew();
      window.open(checkout.paymentUrl, '_self');
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [checkoutService]);

  const handleManageSubscription = useCallback(() => {
    navigate(getAccountSettingsPath(true));
  }, [navigate]);

  // Use a portal to render the story dialog.
  // This will make sure the dialog fills the entire screen.
  const portalRoot = document.getElementById('dialog');
  if (!portalRoot) {
    throw new UnknownError('missing-portal-root-for-dialog');
  }

  const StoryDialogPortal = createPortal(<StoryDialogContainer storiesManager={storiesManager} />, portalRoot);

  return (
    <>
      {StoryDialogPortal}
      <PageRoot>
        <ConfettiAnimation animationData={ConfettiAnimationData} />

        <PageHeader>
          <PageHeaderWordmark />
        </PageHeader>

        <PageBody>
          <HeaderTextWrapper>
            <RMText type="sans" size={isMobile ? 'xxs' : 'm'} color="on-surface-primary" bold align="center">
              DON’T CLOSE THE BOOK YET
            </RMText>
            <RMText type="serif" size={isMobile ? 'xl' : 'xxl'} color="black" align="center">
              Get $25 off your second year of Remento
            </RMText>
            <RMText type="sans" size={isMobile ? 's' : 'm'} color="on-surface-primary" align="center">
              Ensure {storytellersNames} can continue telling stories for another year.
            </RMText>
          </HeaderTextWrapper>

          <OfferWrapper>
            <OfferTextWrapper>
              <RMText type="sans" size={isMobile ? 's' : 'm'} color="on-surface-primary">
                Every day brings new stories worth sharing. Don’t let the next chapter go untold! Get $25 off Remento's
                annual subscription when you renew today.
              </RMText>
              <Bullets>
                <BulletItem>
                  <FontAwesomeIcon icon={faCheck} color="var(--on-surface-primary)" />
                  <RMText type="sans" size={isMobile ? 'xs' : 'm'} bold color="on-surface-primary">
                    More time for recording new stories
                  </RMText>
                </BulletItem>
                <BulletItem>
                  <FontAwesomeIcon icon={faCheck} color="var(--on-surface-primary)" />
                  <RMText type="sans" size={isMobile ? 'xs' : 'm'} bold color="on-surface-primary">
                    More time to edit your written stories
                  </RMText>
                </BulletItem>
                <BulletItem>
                  <FontAwesomeIcon icon={faCheck} color="var(--on-surface-primary)" />
                  <RMText type="sans" size={isMobile ? 'xs' : 'm'} bold color="on-surface-primary">
                    Unlimited new projects
                  </RMText>
                </BulletItem>
                <BulletItem>
                  <FontAwesomeIcon icon={faCheck} color="var(--on-surface-primary)" />
                  <RMText type="sans" size={isMobile ? 'xs' : 'm'} bold color="on-surface-primary">
                    Order books anytime
                  </RMText>
                </BulletItem>
              </Bullets>

              {isMobile && (
                <ClickableText
                  type="sans"
                  size="s"
                  color="on-surface-primary"
                  bold
                  align="center"
                  onClick={handleManageSubscription}
                >
                  Or manage subscription {'>'}
                </ClickableText>
              )}
            </OfferTextWrapper>

            <OfferCardWrapper>
              <OfferCard>
                <OfferCardBubble>
                  <RMText type="sans" size="xs" color="on-surface-primary" bold>
                    SAVE
                  </RMText>
                  <RMText type="sans" size="m" color="on-surface-primary" bold>
                    $25
                  </RMText>
                </OfferCardBubble>
                <OfferCardBanner>
                  <RMText type="sans" size="s" color="on-surface-primary" bold>
                    {offerExpireOn}
                  </RMText>
                </OfferCardBanner>
                <OfferCardContent>
                  <OfferCardPriceWrapper>
                    <RMText
                      type="serif"
                      size="xxl"
                      color="on-surface-tertiary"
                      style={{ textDecoration: 'line-through' }}
                    >
                      $99
                    </RMText>
                    <RMText type="serif" size="xxxl" color="black" bold>
                      $74
                    </RMText>
                  </OfferCardPriceWrapper>
                  <RMText type="sans" size="m" color="on-surface-primary" bold align="center">
                    Per year
                  </RMText>
                  <OfferCardButton background="primary" fullWidth autoLoading onClick={handleRenew}>
                    Claim $25 OFF
                  </OfferCardButton>
                </OfferCardContent>
              </OfferCard>
              {isMobile == false && (
                <ClickableText type="sans" size="s" color="on-surface-primary" bold onClick={handleManageSubscription}>
                  Or manage subscription {'>'}
                </ClickableText>
              )}
            </OfferCardWrapper>
          </OfferWrapper>

          <Divider />

          <MomentsWrapper>
            <MomentsTextWrapper data-center={!showUserStatistics}>
              <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
                {showUserStatistics
                  ? 'Congratulations on an amazing year of saving family memories'
                  : 'Families love using Remento to capture their most precious memories'}
              </RMText>
              {showUserStatistics && (
                <RMText type="sans" size={isMobile ? 's' : 'm'} color="on-surface-primary">
                  Let’s take a look at your top moments from the year.
                </RMText>
              )}
            </MomentsTextWrapper>

            <MomentCards>
              <MomentCard>
                <RMText type="serif" size="xxxl" color="on-surface-primary">
                  {showUserStatistics ? String(statistics.totalStoriesRecorded).padStart(2, '0') : '30K+'}
                </RMText>
                <RMText type="sans" size="m" color="primary-decorative" bold>
                  Stories recorded
                </RMText>
              </MomentCard>

              <MomentCard>
                <RMText type="serif" size="xxxl" color="on-surface-primary">
                  {showUserStatistics
                    ? String(Math.round(statistics.totalRecordedTime / 60)).padStart(2, '0')
                    : '150K+'}
                </RMText>
                <RMText type="sans" size="m" color="primary-decorative" bold>
                  Minutes recorded
                </RMText>
              </MomentCard>

              <MomentCard>
                <RMText type="serif" size="xxxl" color="on-surface-primary">
                  {showUserStatistics ? String(statistics.totalPromptsSent).padStart(2, '0') : '10K+'}
                </RMText>
                <RMText type="sans" size="m" color="primary-decorative" bold>
                  {showUserStatistics ? 'Prompts sent' : 'Views by loved ones'}
                </RMText>
              </MomentCard>
            </MomentCards>
          </MomentsWrapper>

          <StoriesWrapper>
            {userStoriesIds.length > 2 && (
              <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
                These stories really shine
              </RMText>
            )}

            {userStoriesIds.length === 0 && (
              <EmptyStoriesWrapper>
                <EmptyStoryVideo controls playsInline src="/static/perspective-how-to-change.mp4" />
                <EmptyStoryText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
                  Watch Claudette open her book and see her printed stories for the first time!
                </EmptyStoryText>
              </EmptyStoriesWrapper>
            )}

            {userStoriesIds.length > 0 && (
              <SwipableStoriesGrid>
                {userStoriesIds.map((storyId) => (
                  <SwiperSlide key={storyId}>
                    <StoriesItemContainer
                      storyId={storyId}
                      visualization="grid"
                      onAction={() => {
                        setStoriesIds(storiesManager, [storyId]);
                        setActiveStoryId(storiesManager, storyId);
                      }}
                    />
                  </SwiperSlide>
                ))}
                {userStoriesIds.length < 3 && (
                  <SwiperSlide>
                    <StoryTextCard>
                      <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="black">
                        What story do you wish you had in this book?
                      </RMText>
                      <RMText type="sans" size={isMobile ? 's' : 'm'} color="on-surface-primary">
                        It’s not too late to make this the year to create your family’s forever keepsake.{' '}
                      </RMText>
                    </StoryTextCard>
                  </SwiperSlide>
                )}
              </SwipableStoriesGrid>
            )}
          </StoriesWrapper>

          <PromptsWrapper>
            <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
              {prompts.length > 0
                ? 'And there are stories left to tell! Here are your unanswered prompts'
                : 'Need more time to capture your family’s stories?'}
            </RMText>

            <PromptsGrid>
              {prompts.length > 0 && (
                <>
                  {prompts.slice(0, 4).map((prompt, index) => (
                    <PromptCard key={prompt.id}>
                      {index === 3 && prompts.length > 4 ? (
                        <>
                          <Blurred>
                            <RMText type="serif" size="m" color="on-surface-primary">
                              {prompt.question}
                            </RMText>
                          </Blurred>
                          <RemainingPromptsCount type="sans" size="m" bold color="on-surface-primary">
                            +{prompts.length - 3}
                          </RemainingPromptsCount>
                        </>
                      ) : (
                        <RMText type="serif" size="m" color="on-surface-primary">
                          {prompt.question}
                        </RMText>
                      )}
                    </PromptCard>
                  ))}
                </>
              )}
              {prompts.length == 0 && promptTemplatesQuery.data && (
                <>
                  {promptTemplatesQuery.data.slice(0, 4).map((template, index) => (
                    <PromptCard key={template.id}>
                      {index === 3 && promptTemplatesQuery.data.length > 4 ? (
                        <>
                          <Blurred>
                            <RMText type="serif" size="m" color="on-surface-primary">
                              {template.questions[0].text}
                            </RMText>
                          </Blurred>
                          <RemainingPromptsCount type="sans" size="m" bold color="on-surface-primary">
                            +{promptTemplatesQuery.data.length - 3}
                          </RemainingPromptsCount>
                        </>
                      ) : (
                        <RMText type="serif" size="m" color="on-surface-primary">
                          {template.questions[0].text}
                        </RMText>
                      )}
                    </PromptCard>
                  ))}
                </>
              )}
            </PromptsGrid>
          </PromptsWrapper>

          <ClaimOfferCard>
            <ClaimOfferCardTextWrapper>
              <RMText type="sans" size="l" color="on-surface-primary" bold align={isMobile ? 'center' : 'left'}>
                Claim your limited-time offer today!
              </RMText>
              <RMText type="sans" size="s" color="on-surface-primary" align={isMobile ? 'center' : 'left'}>
                {isMobile == false
                  ? 'Dive into another year of telling stories, sharing the memories behind your photos, and giving your family a beautiful keepsake.'
                  : 'Dive into another year of telling stories'}
              </RMText>
            </ClaimOfferCardTextWrapper>
            <ClaimOfferCardButton background="primary" autoLoading onClick={handleRenew}>
              Claim $25 OFF
            </ClaimOfferCardButton>
          </ClaimOfferCard>

          <SubscriptionFaqList />
        </PageBody>
      </PageRoot>
    </>
  );
}

export function AccountRenewPromoPage() {
  return (
    <RementoPage type="default">
      <InternalAccountRenewPromoPage />
    </RementoPage>
  );
}
